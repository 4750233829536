export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'none',
  },
  {
    title: 'Rental Claims',
    route: 'rental',
    icon: 'none'
  },
  {
    title: 'Customers',
    // route: 'second-page',
    icon: 'none'
  },
  {
    title: 'Account',
    // route: 'second-page',
    icon: 'none'
  },
]
